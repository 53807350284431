.leaderboard {
  .select-options-container {
    .ant-select-selection-item,
    .ant-select-selection-item-selected {
      color: #1f1f1f !important;
      font-feature-settings: "liga" off;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
    }
  }
}
