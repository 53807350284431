::-webkit-scrollbar {
  width: 2px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar:hover {
  width: 2px;
  background-color: black;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color:   #FFC700;
}
