.custom-input-number {
  border-radius: 8px;
  border: 1px solid #989898;
  background: #fff;
}

.custom-input-number .ant-input-number-input {
  padding: 0;
}

.custom-input-number .ant-input-number-handler {
  border-inline-start: 0;
}

.custom-input-number .ant-input-number-handler-down {
  border-block-start: 0;
}

.custom-input-number .ant-input-number-handler-wrap {
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
  padding: 8px 0 !important;
  margin-right: 8px;
}
