.CampaignCard {
  .select-options-container {
    .ant-select-selection-item,
    .ant-select-selection-item-selected {
      color: white;
      font-feature-settings: "liga" off;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }

    .ant-select-selector {
      color: white;
      background-color: transparent;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: white;
    }

    .ant-select-item-option,
    .ant-select-item-option-selected,
    .ant-select-item-option-active {
      color: white;
      font-feature-settings: "liga" off;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }

    .ant-select-item-option-active,
    .ant-select-item-option-selected {
      background-color: transparent;
      color: yellow;
      font-feature-settings: "liga" off;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }

    .ant-select-selection-item:focus,
    .ant-select-selector:focus {
      color: white;
    }

    .ant-select-selection-item::after {
      color: white !important;
    }
    .ant-select-open .ant-select-selection-item,
    .ant-select-open .ant-select-selection-item-selected {
      color: white !important;
    }
  }
}
