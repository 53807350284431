.ant-switch.primary-switch {
  background-color: #E2E2E2;

  .ant-switch-handle {
    top: 2.5px;
    width: 16px;
    height: 16px;
    margin: auto 0;
  }

  .ant-switch-inner {
    border: 1px solid #1F1F1F;
  }

  &.ant-switch-checked,
  &:hover:not(.ant-switch-disabled) {
    background-color: #CCC;
  }

  &:hover:not(.ant-switch-disabled) {
    background-color: #E2E2E2;
  }

  &.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: #CCC;
  }

  &.ant-switch-checked .ant-switch-handle::before {
    background-color: #1F1F1F;
  }
}
