.floting {
  .ant-input {
    height: 52px !important;

    &:focus {
      border: 1px solid #484848 !important;
      outline: none !important;
    }

    &.floting {
      padding: 16px 12px 4px 11px;
    }
  }

  .ant-select {
    outline: none !important;

    &:focus {
      outline: none !important;
      border: none !important;
      border-radius: 8px !important;
    }

    &:hover {
      outline: none !important;
    }

    .ant-select-selector {
      padding: 24px 16px 8px;
      border-radius: 8px !important;
      outline: none !important;
      min-height: 30px !important;
      border: none !important;
      &:focus {
        outline: none !important;
        border: none !important;
        border-radius: 8px !important;
      }

      &:hover {
        outline: none !important;
      }

      &.ant-select-single:not(.ant-select-customize-input) {
        padding: 16px 10px 4px 11px;
        border: 1px solid var(--grey-grey-500, #a4a4a4) !important;

        .ant-select-selection-search {
          top: 16px;
        }
      }
    }
  }
}
