.ant-select.select-custom {
  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    border: 1px solid  #989898;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 10px 16px;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    max-height: 36px;
    line-height: 100%;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
  }

  .ant-select-selection-item {
    color: #1F1F1F;
    font-size: 13px;
    font-weight: 600;
    line-height: 130%;
  } 
}
