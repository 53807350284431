.custom-radio {
  .ant-radio-checked .ant-radio-inner {
    background-color: #1f1f1f;
    border-color: #1f1f1f;
  }

  &.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #1f1f1f;
  }

  .ant-radio-checked::after {
    border: 1px solid #1f1f1f;
  }
}
.campaignDetails {
  .ant-upload .ant-upload-btn .ant-upload-drag {
    background-color: white !important;
    border-radius: 8px !important;
    border: none !important;
    outline: none !important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-drag {
    border-radius: 8px !important;
    background-color: white !important;
    border: none !important;
    border-radius: 16px;
  }
  .ant-upload-wrapper {
    background-color: white !important;
    border: 1px dashed var(--grey-grey-400, #a4a4a4) !important;
    border-radius: 8px !important;
  }
}
