.ant-input-affix-wrapper.expand-search-bar {
  &:not(.expanded) {
    background-color: #efefef;
  }
  border-radius: 8px;
  .ant-input-prefix {
    height: auto;
  }

  & > input.ant-input {
    color: #1f1f1f;
    font-feature-settings: "liga" off;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 140%;
  }
}

.ant-input-affix-wrapper .ant-input:not(.expanded) {
  // background-color: #efefef;
}
