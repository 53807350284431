/* custom.css */

/* Default state */
.input-default {
  border-color: transparent;
  outline: none;
}

/* Focus state */
.input-focus {
  outline: none;
  border-color: transparent;
}

/* Hover state */
.input-hover:hover {
  border-color: transparent;
}

/* Focus-visible state */
.input-focus-visible:focus-visible {
  border-color: transparent;
}
