.tag-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: 8px 12px 8px 4px;
  align-items: center;
}
.selectTabs {
  .ant-tabs-nav {
    .ant-tabs-tab {
      color: var(--grey-grey-700, #666);
      text-align: center;
      font-feature-settings: "liga" off;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
    .ant-tabs-tab-active {
      color: red !important;
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }
    .ant-tabs-ink-bar {
      position: absolute;
      background: #ffc700;
      pointer-events: none;
    }
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: "#363636";
  }
}
