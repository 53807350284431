.get-suggestion-modal-popup {
  .ant-modal-content {
    padding: 32px !important;
    max-width: 680px !important;
  }
  .ant-modal-close {
    top: 32px !important;
    right: 32px !important;
  }
}
