.custom-tags-select {
  border: transparent !important;
  border-radius: 8px;
  outline: none;
  padding: auto;

  &:hover {
    outline: none !important;
    border: none !important;
  }
  &:focus {
    outline: none !important;
    border: none !important;
  }
  .ant-select-selection-item {
    height: 25px !important;
    background-color: #ffe58a;
    border-radius: 30px;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    color: var(--yellow-yellow-900, #6c5606);
    font-feature-settings: "liga" off;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

  .ant-select-selection-item-remove {
    color: #6c5606;
    stroke-width: 1.5;
    cursor: pointer;
    &:hover {
      color: #6c5606;
    }
  }

  .ant-select-dropdown-menu-item {
    color: black; // Change the text color for dropdown options
  }
  .ant-select-selection-item-remove svg {
    fill: #6c5606;
    stroke: #6c5606;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  &.ant-select-multiple .ant-select-selection-item:not(:last-child) {
    margin: 8px;
  }

  &.ant-select-multiple .ant-select-selection-overflow {
    row-gap: 8px;
  }
}

.ant-select-dropdown.custom-dropdown-select {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #fff9e6;

    .ant-select-item-option-state {
      color: #1f1f1f;
    }
  }
}
