.ant-checkbox-wrapper.checkbox-custom {
  color: #1f1f1f;
  font-feature-settings: "liga" off;
  font-family: Montserrat;
}

.ant-checkbox-wrapper.primary-checkbox {
}

.ant-checkbox-wrapper.primary-checkbox:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner {
  border-color: #1f1f1f;
}

.ant-checkbox-wrapper.primary-checkbox
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #1f1f1f;
  border: 1px solid #1f1f1f;
}

.ant-checkbox-wrapper.primary-checkbox.ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #1f1f1f;
  border: 1px solid #1f1f1f;
}

.ant-checkbox-wrapper.secondary-checkbox {
}

.ant-checkbox-wrapper.secondary-checkbox:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: white;
}

.ant-checkbox-wrapper.secondary-checkbox
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: white;
  border: 1px solid white;
}

.ant-checkbox-wrapper.secondary-checkbox.ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: white;
  border: 1px solid white;
}

.ant-checkbox-wrapper.secondary-checkbox
  .ant-checkbox
  .ant-checkbox-inner:after {
  border-color: #1f1f1f;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #989898;
}
