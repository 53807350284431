.steps-head {
  .ant-steps-item-container {
    width: fit-content;
  }

  .ant-step-content {
    display: none;
  }

  .ant-steps-item-title {
    width: fit-content;
    color: var(--primary-black, #1f1f1f);
    font-feature-settings: "liga" off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: #1f1f1f !important;
    border: none;
  }

  .ant-steps-finish .anticon-check::before {
    display: inline-block;
    width: 11px;
    height: 8px;
  }

  .ant-steps-item-title:after {
    background-color: #b18a00 !important;
    // width: 14px !important;
    margin: 0 auto;
  }
  .ant-steps-item .ant-steps-item-wait {
    // max-width: 310px;
  }
  .ant-steps.ant-steps-horizontal {
    display: flex;
    justify-content: center;
    // gap: 16px;
  }
}

.ant-steps.ant-steps-horizontal.create-campaign-steps {
  .ant-steps-item {
    position: relative;
    max-width: fit-content;
  }

  &.ant-steps.ant-steps-small.ant-steps-horizontal:not(
      .ant-steps-label-vertical
    )
    .ant-steps-item:not(:first-child) {
    padding-inline-start: 30px;
  }

  .ant-steps-item:not(:last-child) {
    margin-right: 16px;
  }

  .ant-steps-item:not(:first-child)::after {
    position: absolute;
    top: 12px;
    display: block;
    left: 0;
    content: "";
    height: 1px;
    width: 14px;
    background-color: #b18a00;
    margin: 0 auto;
    z-index: 999;
  }

  &.ant-steps.ant-steps-small .ant-steps-item-title {
    padding-inline-end: 0;
  }

  .ant-steps-item-title {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: initial;
  }

  .ant-steps-item-icon {
    color: #fff;
    font-feature-settings: "liga" off;
    font-family: Montserrat;
    border-radius: 50%;
    border: 1px solid #8c6d00;
    background-color: transparent;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #8c6d00;
    font-size: 13px;
    font-weight: 500;
    line-height: 130%;
  }

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 130%;
  }
}

.ant-steps.ant-steps-vertical.create-campaign-steps {
  flex-direction: row;
  justify-content: center;

  .ant-steps-item-container .ant-steps-item-title,
  & > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after,
  & > .ant-steps-item .ant-steps-item-content {
    display: none;
  }

  & > .ant-steps-item {
    max-width: fit-content;
  }

  & > .ant-steps-item:not(:first-child) {
    padding-inline-start: 30px;
  }

  .ant-steps-item:not(:first-child)::after {
    position: absolute;
    top: 12px;
    display: block;
    left: 0;
    content: "";
    height: 1px;
    width: 14px;
    background-color: #b18a00;
    margin: 0 auto;
    z-index: 999;
  }
}
