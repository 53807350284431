.ant-table-wrapper.pick-creator-table {
  .ant-table-thead > tr > th {
    color: #808080;
    font-feature-settings: "liga" off;
    font-family: Montserrat;
    text-transform: uppercase;
  }

  .table-row-checked {
    background-color: #fff9e6;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #efefef;
    background-color: transparent;
    padding: 8px 0;
  }

  .ant-table-tbody > tr > td {
    color: #14161e;
    font-feature-settings: "liga" off;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }

  .ant-table & :hover,
  tr:hover,
  thead:hover {
    background: #fff9e6 !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }

  .ant-table-tbody > tr:not(:last-child) > td {
    border-bottom: 1px solid #efefef;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }

  .ant-table-tbody > tr > td {
    padding: 16px 0;
  }
}
