.Brand{
    .select-options-container {

        .ant-select-selection-item,
        .ant-select-selection-item-selected {
            color: #1F1F1F !important; 
        
    }
  }
}


  