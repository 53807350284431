.ant-input-affix-wrapper.primary-input {
  padding: 8px 16px 8px 12px;
  border-radius: 8px;
  border: 1px solid #989898;
  background: #FFF;
  height: 36px;

  & > input.ant-input {
    color: #1F1F1F;
    font-feature-settings: 'liga' off;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 140%;
  }
}
