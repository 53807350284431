.float-label {
  position: relative;
  margin-bottom: 12px;
  border: 1px solid #a4a4a4 !important;
  border-radius: 8px;
  width: 100%;
}

.label {
  position: absolute;
  color: var(--primary-black, #1f1f1f);
  font-feature-settings: "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
}

.label-float {
  top: 0;
  padding-top: 8px;
  width: calc(100% - 20px);
  font-size: 11px;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  background-color: white !important;
  z-index: 2;
  font-weight: 500;
  line-height: 100%;
  color: #808080;
}
.label-float:hover {
  border: 1px solid #484848 !important;
}
